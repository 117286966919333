import React, { useState } from 'react'
import Deals from './deals/Deals'
import tools from '../tools/tools.jpg'
import lltea1 from '../images/lltea1.jpg'
import lltea2 from '../images/lltea2.jpg'
import lltea3 from '../images/lltea3.jpg'
import lltea4 from '../images/lltea4.jpg'
import tbtea1 from '../images/tbtea1.jpg'
import tbtea2 from '../images/tbtea2.jpg'
import tbtea3 from '../images/tbtea3.jpg'
import tbtea4 from '../images/tbtea4.jpg'
import teacon from './teacon.jpg'
import guide from '../guides/guide.jpg'
import FadeIn from 'react-fade-in';
import {Link, withRouter} from 'react-router-dom';

const TeaOptions = () => {
  const[looseleaf, setLooseleaf] = useState(false)
  const[bagged, setBagged] = useState(false)
  const[deals, setDeals] = useState(false)
  
  
  const showBagged = () => {
    setBagged(true)
    setLooseleaf(false)
    setDeals(false)
  }



  const showLooseleaf = () => {
    setLooseleaf(true)
    setBagged(false)
    setDeals(false)
 }

 const showDeals = () => {
   setDeals(true)
  setLooseleaf(false)
  setBagged(false)
}
  
  
  
  
  
  
  return(
  <FadeIn delay={500}>
  <div>
    <br/>
    <div style={{background: 'white', width: '100%'}}>
      <div className='butt' style={{display:'flex', justifyContent:'space-evenly'}}>
      <button onClick={() => showLooseleaf()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Looseleaf</button>
      <button  onClick={() => showBagged()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Bagged</button>
      <button  onClick={() => showDeals()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Deals</button>
      </div>
      <br/>
      <div className='toolbox'>
        {looseleaf ?  <FadeIn><br/><div>Looseleaf
          <p>Make tea the raw way with leaves and herbs. Check out these assorted samplers to find out your favorite flavors.</p>
          <hr/>
          <br/>
          <div className='display'>
          <div >
          <a href='https://www.amazon.com/gp/product/B004AFKYDE/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B004AFKYDE&linkCode=as2&tag=teahold-20&linkId=6024537d464a97ac53e80f1cd94920e6' target="_blank" style={{textDecoration:'none'}}>
          <img src={lltea1} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 15 Pack</p>
          </a>
          </div>
          <div >
          <a href='https://www.amazon.com/gp/product/B00O80NU00/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00O80NU00&linkCode=as2&tag=teahold-20&linkId=daff19d479f13f3bea2c20df0faf57ac' target="_blank" style={{textDecoration:'none'}}>
          <img src={lltea2} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 28 Pack</p>
          </a>
          </div>
          <div >
          <a href='https://www.amazon.com/gp/product/B01JAUQKAY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01JAUQKAY&linkCode=as2&tag=teahold-20&linkId=567dc6ce69546f24fa21e63aaadb6b11' target="_blank" style={{textDecoration:'none'}}>
          <img src={lltea3} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Large 12 Pack Variety</p>
          </a>
          </div>
          <div >
          <a href='https://www.amazon.com/gp/product/B08L78Q19W/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08L78Q19W&linkCode=as2&tag=teahold-20&linkId=64b4935fce52f42f79455efcd0f497eb' target="_blank" style={{textDecoration:'none'}}>
          <img src={lltea4} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 6 Pack</p>
          </a>
          </div>
          </div>
          <br/>
        </div></FadeIn> : ''}
        {bagged ? <FadeIn><br/><div>Bagged
          <p>Make tea the quickest way. Check out these variety packs to help you find the right flavors.</p>
          <hr/>
          <br/>
          <div className='display'>
          <div >
          <a href='https://www.amazon.com/gp/product/B0062JSYEW/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0062JSYEW&linkCode=as2&tag=teahold-20&linkId=78f0d9c48e0a480b82d9e8203b61ffa2' target="_blank" style={{textDecoration:'none'}}>
          <img src={tbtea1} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 48 Pack</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B07WQFWGHV/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07WQFWGHV&linkCode=as2&tag=teahold-20&linkId=6de054544d9ecd4a28ff5256b4cd1b94' target="_blank" style={{textDecoration:'none'}}>
          <img src={tbtea2} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 6 Pack</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B005Q8BIK2/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B005Q8BIK2&linkCode=as2&tag=teahold-20&linkId=a472bd649898ae9c3d70705e35bcaba0' target="_blank" style={{textDecoration:'none'}}>
          <img src={tbtea3} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 6 Pack</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B0764JLLZG/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0764JLLZG&linkCode=as2&tag=teahold-20&linkId=933bbdcf22e02527eb5259b396a5e7ad' target="_blank" style={{textDecoration:'none'}}>
          <img src={tbtea4} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Variety 42 Pack</p>
          </a>
          </div>
          </div>
          <br/>
        </div></FadeIn> : ''}
        {deals ?  <FadeIn><br/><div>Weelkly Deals
          <p>Some of the best deals online. Get ready for a good deal! Updated weekly!</p>
          <div style={{fontSize:'20px'}}>You may need to disable your adblockers to view these</div>
          <hr/>
          <br/>
          <div className='display'>
          <Deals />
          </div>
        </div></FadeIn> : ''}       
      </div>
    </div>
  </div>
  </FadeIn>
  )
};

export default TeaOptions;