import React, { useEffect, useState, useRef } from 'react'
import NavbarItemsHidden from './NavbarItemsHidden'
import NavbarItemsHiddenx from './NavbarItemsHiddenx'
import FadeIn from 'react-fade-in';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const Hamburger = () => {

  const[openHamburger, setOpenHamburger] = useState(true)
  const[visible, setVisible] = useState(true)


  function hideMe() {
    setTimeout(() => setVisible(false), 650);
  }

  let HamburgerOpen = () => {
    setOpenHamburger(false)
    hideMe()
  }

  let HamburgerClose = () => {
    setOpenHamburger(true)
    hideMe()
  }

  let menuRef = useRef()

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
      setOpenHamburger(true)
    }};
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  return (
    <div ref={menuRef}>
      {openHamburger ? <div className='bar-container' onClick={() => HamburgerOpen()}>
        <div className='bar-1'></div>
        <div className='bar-2'></div>
        <div className='bar-3'></div>
        <TransitionGroup><CSSTransition key={false} timeout={100} classNames='trans'><NavbarItemsHiddenx /></CSSTransition></TransitionGroup>
      </div> : <div  className='bar-container' onClick={() => HamburgerClose()}>
        <div className='change1 bar-1'></div>
        <div className='change2 bar-2'></div>
        <div className='change3 bar-3'></div>
        <TransitionGroup><CSSTransition key={true} timeout={100} classNames='trans'><NavbarItemsHidden  /></CSSTransition></TransitionGroup>
      </div>}
    </div>
  )
}


export default Hamburger;