import React from 'react'
import tools from '../tools/tools.jpg'
import teacon from '../tea/teacon.jpg'
import guide from '../guides/guide.jpg'
import FadeIn from 'react-fade-in';
import {Link, withRouter} from 'react-router-dom';

const Options = () => {
  return(
  <FadeIn delay={500}>
  <div>
    <div style={{background: 'white', width: '100%'}}>
      <p style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px'}}>Enter the hold</p>
      <div className='toolbox'>
        <Link style={{ textDecoration: 'none', color: 'white' }} to='/tools'>
      <p className='icon'>
        <img src={tools}/>
        <p style={{textAlign:'center', fontWeight:'bold', marginTop:'5px', paddingTop:'10px', color:'black'}}>Tools</p>
      </p>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'white' }} to='/guides'>
      <p className='icon'>
      <img src={guide}/>
        <p style={{textAlign:'center', fontWeight:'bold', marginTop:'5px', color:'black'}}>Guides</p>
      </p>
      </Link>
      <Link style={{ textDecoration: 'none', color: 'white' }} to='/tea'>
      <p className='icon'>
      <img src={teacon}/>
        <p style={{textAlign:'center', fontWeight:'bold', marginTop:'5px', color:'black'}}>Tea</p>
      </p>
      </Link>
      </div>
    </div>
  </div>
  </FadeIn>
  )
};

export default Options;