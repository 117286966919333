import React from 'react'
import Home from './core/Home'
import {BrowserRouter, Route, Link, Switch, useParams} from 'react-router-dom'
import Tools from './tools/Tools'
import Tea from './tea/Tea'
import Guides from './guides/Guides'
import BlogHome from './blogs/BlogHome'
import Article from './blogs/Article'


const Routes = (articles, id) => {
  return(
  <BrowserRouter>
    <Switch>
    <Route path='/' exact component={Home} />
    <Route path='/tools' exact component={Tools} />
    <Route path='/tea' exact component={Tea} />
    <Route path='/guides' exact component={Guides} />
    <Route path='/BlogHome' exact component={BlogHome} />
    <Route path='/Article/:id' exact component={BlogHome} />
    {/* <Route path={`/Article/${articles}/:id`} exact component={BlogHome} /> */}
    </Switch>
  </BrowserRouter>
  )
};

export default Routes;