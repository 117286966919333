import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const TermLL = () => {

    const [open, setOpen] = useState(false);
   
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);




    return (
      <div>
        <div className='terms'>
        <div style={{fontSize:'40px', cursor:'pointer'}} onClick={onOpenModal}>Looseleaf</div>
        <Modal open={open} onClose={onCloseModal} center className='customModal'>
          <h2>Tea leaves and herbs not in a bag</h2>
        </Modal>
        </div>
        <br/>
      </div>
    );
}

export default TermLL