import React, { useState } from 'react';
import TermSteep from './TermSteep';
import TermLL from './TermLL';
import TermInfuser from './TermInfuser';
import TermCs from './TermCs';

const Terms = () => {
    return (
      <div>
        <TermCs />
        <TermInfuser />
        <TermLL />
        <TermSteep />
      </div>
    );
    };

export default Terms