import React from 'react'
import Lay from '../core/Lay';
import FadeIn from 'react-fade-in';
import Hero from '../core/Hero'
import ToolOptions from './ToolOptions';
import ToolOptions2 from './ToolOptions2';


const Tools = () => {
  return(
  <Lay>
    <FadeIn delay={1000} transitionDuration={2600}>
    <div className='overlay'></div>
    </FadeIn>
    <FadeIn className='herot' delay={500} transitionDuration={2600}>
    <h1 >Learn the techniques
    <p style={{fontSize: '2rem', textAlign:'center'}}>Get the right tools</p>
    </h1>
    </FadeIn>
    <FadeIn>
    <div className='heroI herotools'></div>
    </FadeIn>
    <ToolOptions />
  </Lay>
  )
};

export default Tools;