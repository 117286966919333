import React from 'react'
import {Link, withRouter} from 'react-router-dom';


const NavbarItems = () => {




  return(
    <div className='nav-items'>
      <Link to='/tools' style={{textDecoration:'none'}}>
        <div>Tools</div>
      </Link>
      <Link to='/guides' style={{textDecoration:'none'}}>
        <div>Guides</div>
      </Link>
      <Link to='/tea' style={{textDecoration:'none'}}>
        <div>Tea</div>
      </Link>     
    </div>
  )
};


export default NavbarItems;