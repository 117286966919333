import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import Options from './Options'
import Footer from './Footer'
import FadeIn from 'react-fade-in';

const Lay = ({className, children}) => {
  return (
  <div style={{height:'100%', width:'100%'}}>
    <Navbar />
    <div className={className}>{children}</div>
    <Footer />
  </div>
  )
};

export default Lay;