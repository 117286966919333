import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import Options from './Options'
import Footer from './Footer'
import FadeIn from 'react-fade-in';
import Lay from './Lay'
import Teaimage from './tea.jpg'

const Home = () => {
  return(
  <div style={{height:'100%', width:'100%'}}>
    <Lay>
    <FadeIn delay={1000} transitionDuration={2600}>
    <div className='overlay'></div>
    </FadeIn>
    <FadeIn className='herot' delay={500} transitionDuration={2600} >
    <h1>Welcome to Tea Hold
    <p style={{fontSize: '2rem', textAlign:'center'}}> Get your tea sources here</p>
    </h1>
    </FadeIn>
    <FadeIn>
    <div className='heroI herotea'></div>
    </FadeIn>
    <Options />
    {/* <div>Latest info</div>
    <div>Top info</div> */}
  </Lay>
  </div>
  )
};

export default Home;