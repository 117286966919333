import React from 'react'

const Footer = () => {
  return(
  <div>
    <p className='footer'>Contact Us At teahold1@gmail.com</p>
  </div>
  )
};

export default Footer;