import React, { useState } from 'react'
import tools from './tools.jpg'
import teapot1 from '../images/teapot1.jpg'
import teapot2 from '../images/teapot2.jpg'
import ladle from '../images/ladle.jpg'
import teacup1 from '../images/teacup1.jpg'
import teamaker1 from '../images/teamaker1.jpg'
import teamaker2 from '../images/teamaker2.jpg'
import infuser from '../images/infuser.jpg'
import boiler from '../images/boiler.jpg'
import teacon from '../tea/teacon.jpg'
import guide from '../guides/guide.jpg'
import FadeIn from 'react-fade-in';
import {Link, withRouter} from 'react-router-dom';

const ToolOptions = () => {
  const[classic, setClassic] = useState(false)
  const[modern, setModern] = useState(false)
  
  
  const showModern = () => {
    setModern(true)
    setClassic(false)
  }



  const showClassic = () => {
    setClassic(true)
    setModern(false)
 }
  
  
  
  
  
  
  return(
  <FadeIn delay={500}>
  <div>
    <br/>
    <div style={{background: 'white', width: '100%'}}>
      <div className='butt' style={{display:'flex', justifyContent:'space-evenly', position:'relative'}}>
      <button onClick={() => showClassic()} className='icon' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'13px', borderRadius:'20%', zIndex:'20'}}>Classic</button>
      <button  onClick={() => showModern()} className='icon' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'13px', borderRadius:'20%', zIndex:'20'}}>Modern</button>
      </div>
      <br/>
      <div className='toolbox'>
        {classic ?  <FadeIn><br/><div>Classic
          <p>Make tea the way it has been made for centuries. These are the most common tools used by people all over the world.</p>
          <hr/>
          <br/>
          <div className='display'>
          <div >
          <a href='https://www.amazon.com/gp/product/B01N5JTAAD/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01N5JTAAD&linkCode=as2&tag=teahold-20&linkId=a19a0bf769c47d76aaf98ada2f4c5eba' target="_blank" style={{textDecoration:'none'}}>
          <img src={teapot1} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Japanese Tetsubin</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B01JOXW182/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01JOXW182&linkCode=as2&tag=teahold-20&linkId=5edfa9da0c7f71ed2840b29dd74b16e3' target="_blank" style={{textDecoration:'none'}}>
          <img src={teapot2} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Stock Pot</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B08FD8LRTM/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08FD8LRTM&linkCode=as2&tag=teahold-20&linkId=00ee2f708a260b6b1bba2301c9b02761' target="_blank" style={{textDecoration:'none'}}>
          <img src={ladle} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Ladle</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B075GKRDYH/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B075GKRDYH&linkCode=as2&tag=teahold-20&linkId=ad28b37d6b5fe1bfd9c6b068a93b1057' target="_blank" style={{textDecoration:'none'}}>
          <img src={teacup1} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Wood Tea Cups</p>
          </a>
          </div>
          </div>
          <br/>
        </div></FadeIn> : ''}
        {modern ? <FadeIn><br/><div>Modern
          <p>Make tea the way most people today make it. There are new and quick ways to make tea to help adapt to your lifestyle.</p>
          <hr/>
          <br/>
          <div className='display'>
          <div >
          <a href='https://www.amazon.com/gp/product/B084NZLQ6L/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B084NZLQ6L&linkCode=as2&tag=teahold-20&linkId=236e06b608e1828ce3d4358aaa541660' target="_blank" style={{textDecoration:'none'}}>
          <img src={teamaker1} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Automated Tea Maker</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B078J3C15N/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B078J3C15N&linkCode=as2&tag=teahold-20&linkId=42e2f4eb1d48b7ae9610d10dd0b8639c' target="_blank" style={{textDecoration:'none'}}>
          <img src={teamaker2} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Bottom Dispensing Tea Maker</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B07GF4WYGT/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GF4WYGT&linkCode=as2&tag=teahold-20&linkId=e7840ea316969f2bc3df183a620af3a8' target="_blank" style={{textDecoration:'none'}}>
          <img src={infuser} style={{backgroundSize:'cover', height:'200px', width:'200px'}} />
          <p>Cup Infuser</p>
          </a>
          </div>
          <div>
          <a href='https://www.amazon.com/gp/product/B00I32GPVK/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00I32GPVK&linkCode=as2&tag=teahold-20&linkId=6b4d075d981274df30f0340dc713874d' target="_blank" style={{textDecoration:'none'}}>
          <img src={boiler} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>
          <p>Water Boiler</p>
          </a>
          </div>
          </div>
          <br/>
        </div></FadeIn> : ''}       
      </div>
    </div>
  </div>
  </FadeIn>
  )
};

export default ToolOptions;