import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Link, useParams } from 'react-router-dom';
import FadeIn from 'react-fade-in';
import {API} from '../config'




const BASE_URL = 'http://localhost:1337/articles'

const BlogThumb = (id) => {

const [articles, setArticles] = useState([])
const [isLoading, setIsLoading] = useState(false)
const [error, setError] = useState(null)
const [Image, setImage] = useState([])


// useEffect(() =>{
//   setIsLoading(true)
//   fetch(`${API}/articles`)
//   .then(res => {
//     if(res.ok) {
//       return res.json();
//     } else {
//       throw Error("error fecthing posts")
//     }
//   })
//   .then(articles => {
//     setArticles(articles);
//     setIsLoading(false)
//   })
//   .catch (error => {
//     setError(error)
//   })
// }, [])


useEffect(() =>{
  setIsLoading(true)
  fetch(`${API}/articles`, {
    cors: {
      origin: "http://localhost:3000",
      methods: ["GET", "POST"],
      allowedHeaders: ["my-custom-header"],
      credentials: true
    },
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  .then(res => {
    if(res.ok) {
      return res.json();
    } else {
      throw Error("error fecthing posts")
    }
  })
  .then(articles => {
    setArticles(articles);
    setIsLoading(false)
  })
  .catch (error => {
    setError(error)
  })
}, [])


if (error){
return <p style={{color:'red'}}>{error.message}</p>
}

if (isLoading){
  // return <p style={{display:'flex', justifyContent:'center', alignContent:'center', textAlign:'center', fontSize:'30px'}}>Loading posts...</p>
  return <Loader type="Rings" color="black" height={80} width={80} style={{display:'flex', justifyContent:'center', alignContent:'center'}}/>
}

return(
  <FadeIn>
  <div className='blogthumb'>
    {articles.map(articles => (
      <Link to={`/Article/${articles.id}`}>
      {/* //  <Link key={id} to={`/Article/${articles}/${id}`}> */}
        {/* <div style={{padding:'20px'}}> */}
        <div >
        <h3>{articles.Title}</h3>
        <img src={`${API}` + articles.img.url} style={{backgroundSize:'cover', height:'200px', width:'200px'}}/>       
        </div>
      </Link>
    ))}
  </div>
  </FadeIn>
)



}

export default BlogThumb