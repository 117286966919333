import React from 'react';
import { Link } from 'react-router-dom';
import Blog2 from '../blogs/Blog2'
import BlogThumb from '../blogs/BlogThumb'


const GuideBlogs = () => {
 return(
  <div className='guideblogs'>
    {/* <div>
      <Link to='/BlogHome'>
      <p>1</p>
      </Link>
    </div>
    <div>
      <p>Coming soon</p>
    </div>
    <div>
      <p>Coming soon</p>
    </div>
    <div>
      <p>Coming soon</p>
    </div>
    <div>
      <p>Coming soon</p>
    </div>
    <div>
      <p>Coming soon</p>
    </div> */}
    <BlogThumb />
  </div>
 ) 
}

export default GuideBlogs;