import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const TermSteep = () => {

    const [open, setOpen] = useState(false);
   
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);




    return (
      <div>
        <div className='terms'>
        <div style={{fontSize:'40px', cursor:'pointer'}} onClick={onOpenModal}>Steep</div>
        <Modal open={open} onClose={onCloseModal} center className='customModal'>
          <h2>To allow dry ingredients, such as coffee, tea, or spices, to soak in a liquid until the liquid takes on the flavor of the dry ingredient.</h2>
        </Modal>
        </div>
        <br/>
      </div>
    );
}

export default TermSteep