import React, { useState } from 'react'
import tools from '../tools/tools.jpg'
import teacon from '../tea/teacon.jpg'
import guide from './guide.jpg'
import FadeIn from 'react-fade-in';
import {Link, withRouter} from 'react-router-dom';
import Blog from '../blogs/Blog';
import Blog2 from '../blogs/Blog2';
import GuideBlogs from './GuideBlogs';
import Terms from './terms/Terms'

const GuideOptions = () => {
  const[guides, setGuides] = useState(false)
  const[culture, setCulture] = useState(false)
  const[terms, setTerms] = useState(false)
  
  
  const showGuides = () => {
    setGuides(true)
    setCulture(false)
    setTerms(false)
  }



  const showCulture = () => {
    setCulture(true)
    setGuides(false)
    setTerms(false)
 }

 const showTerms = () => {
  setTerms(true)
   setGuides(false)
   setCulture(false)
}
  
  
  
  
  
  
  return(
  <FadeIn delay={500}>
  <div>
    <br/>
    <div style={{background: 'white', width: '100%'}}>
      <div className='butt' style={{display:'flex', justifyContent:'space-evenly'}}>
      <button onClick={() => showGuides()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Guides</button>
      {/* <button  onClick={() => showCulture()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Coming Soon</button> */}
      <button  onClick={() => showTerms()} className='icon ' style={{textAlign:'center', fontSize:'2em', fontWeight:'bold', paddingBottom:'20px', border:'solid black 8px', paddingTop:'10px', padding:'20px', borderRadius:'20%', zIndex:'20'}}>Terms</button>
      </div>
      <br/>
      <div className='toolbox'>
        {guides ?  <FadeIn><br/><div>Guides
          <p>Learn how to make tea different ways with different tools. From traditional to modern find your favorite style.</p>
          <hr/>
          <br/>
          <div className='display'>
            <GuideBlogs />
          </div>
        </div></FadeIn> : ''}
        {culture ? <FadeIn><br/><div>Coming Soon
          {/* <p>Find out where certain tea and flavors come from all around the world.</p> */}
          <p>Coming Soon</p>
          <hr/>
          <br/>
          <div className='display'>
          {/* <div >
          <img src={tools} style={{backgroundSize:'cover', height:'100px', width:'100px'}}/>
          <p>Item 1</p>
          </div>
          <div>
          <img src={tools} style={{backgroundSize:'cover', height:'100px', width:'100px'}}/>
          <p>Item 2</p>
          </div>
          <div>
          <img src={tools} style={{backgroundSize:'cover', height:'100px', width:'100px'}}/>
          <p>Item 3</p>
          </div>
          <div>
          <img src={tools} style={{backgroundSize:'cover', height:'100px', width:'100px'}}/>
          <p>Item 4</p>
          </div> */}
          <p>Comming Soon</p>
          </div>
        </div></FadeIn> : ''}
        {terms ?  <FadeIn><br/><div>Terms
          <p>Learn the terminology of tea.</p>
          <hr/>
          <br/>
          <div className='display'>
            <Terms />
          </div>
        </div></FadeIn> : ''}       
      </div>
    </div>
  </div>
  </FadeIn>
  )
};

export default GuideOptions;