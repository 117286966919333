import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const TermInfuser = () => {

    const [open, setOpen] = useState(false);
   
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);




    return (
      <div>
        <div className='terms'>
        <div style={{fontSize:'40px', cursor:'pointer'}} onClick={onOpenModal}>Infuser</div>
        <Modal open={open} onClose={onCloseModal} center className='customModal'>
          <h2>A filter to hold loose leaf tea during a steep. Seperates leaves, herbs, and spices out of your tea.</h2>
        </Modal>
        </div>
        <br/>
      </div>
    );
};

export default TermInfuser;