import React from 'react'
import Lay from '../core/Lay';
import FadeIn from 'react-fade-in';
import TeaOptions from './TeaOptions';


const Tea = () => {
  return(
    <Lay>
    <FadeIn delay={1000} transitionDuration={2600}>
    <div className='overlay'></div>
    </FadeIn>
    <FadeIn className='herot' delay={500} transitionDuration={2600}>
    <h1 >Find the right one
    <p style={{fontSize: '2rem', textAlign:'center'}}>So many choices</p>
    </h1>
    </FadeIn>
    <FadeIn>
    <div className='heroI heroteafield'></div>
    </FadeIn>
    <TeaOptions />
  </Lay>
  )
}

export default Tea;