import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import FadeIn from 'react-fade-in';
import {API} from '../config'


const BASE_URL = 'http://localhost:1337/articles'
const WEB_URL = 'http://165.22.180.122:1337/articles'

const Article = () => {
let { id } = useParams();
const [articles, setArticles] = useState([])
const [isLoading, setIsLoading] = useState(false)
const [error, setError] = useState(null)
const [Image, setImage] = useState([])


useEffect(() =>{
  setIsLoading(true)
  fetch(`${API}/articles`)
  .then(res => {
    if(res.ok) {
      return res.json();
    } else {
      throw Error("error fecthing posts")
    }
  })
  .then(articles => {
    setArticles(articles);
    setIsLoading(false)
  })
  .catch (error => {
    setError(error)
  })
}, [])

if (error){
return <p style={{color:'red'}}>{error.message}</p>
}

if (isLoading){
  // return <p style={{display:'flex', justifyContent:'center', alignContent:'center', textAlign:'center', fontSize:'30px'}}>Loading posts...</p>
  return <Loader type="Rings" color="black" height={80} width={80} style={{display:'flex', justifyContent:'center', alignContent:'center'}}/>
}


// let articlesid = articles.id
// return(
//   <div className='blog'>
//     <h1>With Hooks</h1>
//     {articles.map((articles) => (
//       <div>
//         <div id={id}>ID { id }</div>
//         <div className='blogf'>
//         <h2 id={id}>{articles.Title}</h2>
//         <img id={id} src={'http://localhost:1337' + articles.img.url} style={{width: '60vw', height:'40vw', backgroundSize:'cover'}}/>
//         <p id={id}>{articles.Content}</p>
        
//         </div>
//       </div>
//     ))}
//   </div>
// )



return(
  <div className='blog'>
    {/* <h1>With Hooks</h1> */}
    {articles.map((articles) => {
      window.scrollTo(0, 0)
      if ( articles.id == id) {
      return(
        <FadeIn>
      <div>
        {/* <div>ID { articles.id }</div> */}
        <div className='blogf'>
        <h1>{articles.Title}</h1>
        <img src={`${API}` + articles.img.url} className='blog-pic' style={{width: '60vw', height:'40vw', backgroundSize:'cover'}}/>
        <ReactMarkdown className='blog-content'>{articles.Content}</ReactMarkdown>
        
        </div>
      </div>
      </FadeIn>
    )} })}
  </div>
)



}

export default Article