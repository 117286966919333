import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



const TermCs = () => {

    const [open, setOpen] = useState(false);
   
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const bg = {
      overlay: {
        // background: "black"
      }
    };

    const myRef = React.useRef(null);


    return (
      <div>
        <div className='terms'>
        <div style={{fontSize:'40px', cursor:'pointer'}} onClick={onOpenModal}>Camellia sinensis</div>
        <Modal open={open} onClose={onCloseModal} center styles={bg} container={myRef.current} >
          <h2>The main plant used to make tea, usually white, oolong, and black tea. </h2>
        </Modal>
        </div>
        <br/>
      </div>
    );
};

export default TermCs;