import React from 'react'
import {Link, withRouter} from 'react-router-dom';
import Hamburger from './Hamburger';
import NavbarItems from './NavbarItems';
import NavbarItemsHidden from './NavbarItemsHidden';

const Navbar = () => {
  return(
  <div className='navbar'>
    <div className='nav-container'>
      <Link to='/'>
        <div className='logo'></div>
      </Link>
      <NavbarItems />
      <Hamburger />
    </div>
  </div>
  )
};

export default Navbar;