import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import FadeIn from 'react-fade-in';
import {API} from '../../config'

const BASE_URL_Deals = 'http://localhost:1337/deals'

const Deals = () => {
let { id } = useParams();
const [deals, setDeals] = useState([])
const [isLoading, setIsLoading] = useState(false)
const [error, setError] = useState(null)
// const [Image, setImage] = useState([])


useEffect(() =>{
  setIsLoading(true)
  fetch(`${API}/deals`)
  .then(res => {
    if(res.ok) {
      return res.json();
    } else {
      throw Error("Comming Soon")
    }
  })
  .then(deals => {
    setDeals(deals);
    setIsLoading(false)
  })
  .catch (error => {
    setError(error)
  })
}, [])

// useEffect(() =>{
//   const fetchDeals = async () => {
//   setIsLoading(true)

//   const res = await fetch(BASE_URL_Deals)
//       const deals = await res.json();
//       setDeals(deals);
//       setIsLoading(false)
 
  
 
  
//     // let sd = await setDeals(deals);
//     // return sd && setIsLoading(false)
    
    

// }

//   fetchDeals()

// }, [])

if (error){
return <p style={{color:'black'}}>{error.message}</p>
}

if (isLoading){
  // return <p style={{display:'flex', justifyContent:'center', alignContent:'center', textAlign:'center', fontSize:'30px'}}>Loading posts...</p>
  return <Loader type="Rings" color="black" height={80} width={80} style={{display:'flex', justifyContent:'center', alignContent:'center'}}/>
}


// let articlesid = articles.id
// return(
//   <div className='blog'>
//     <h1>With Hooks</h1>
//     {articles.map((articles) => (
//       <div>
//         <div id={id}>ID { id }</div>
//         <div className='blogf'>
//         <h2 id={id}>{articles.Title}</h2>
//         <img id={id} src={'http://localhost:1337' + articles.img.url} style={{width: '60vw', height:'40vw', backgroundSize:'cover'}}/>
//         <p id={id}>{articles.Content}</p>
        
//         </div>
//       </div>
//     ))}
//   </div>
// )



return(
  <div className='blogthumb'>
    {/* <h1>With Hooks</h1> */}
    {deals.map((deals) => {
      // window.scrollTo(0, 0)
      if ( deals.id == deals.id) {
      return(
        <FadeIn>
      <div>
        {/* <div>ID { articles.id }</div> */}
        <div >
        {/* <h1>{deals.Title}</h1> */}
        {/* <img src={'http://localhost:1337' + articles.img.url} className='blog-pic' style={{width: '60vw', height:'40vw', backgroundSize:'cover'}}/> */}
        {/* <ReactMarkdown className='blog-content'>{deals.link}</ReactMarkdown> */}
        <iframe style={{width:'120px', height:'240px', marginWidth:'0', marginHeight:'0', scrolling:'no', frameborder:'0'}} src={deals.link} />
        <br/>
        <br/>
        </div>
      </div>
      </FadeIn>
    )} else {
      return(
        <div>Comming Soon</div>
      )
    } })}
  </div>
)



}

export default Deals