import React from 'react'
import Lay from '../core/Lay'
import Blog2 from './Blog2'
import FadeIn from 'react-fade-in';
import Article from './Article'


const BlogHome = () => {
  return(
    <Lay>
    <FadeIn delay={1000} transitionDuration={2600}>
    <div className='overlay'></div>
    </FadeIn>
    <FadeIn className='herot' delay={500} transitionDuration={2600}>
    <h1 >Guides and tea terms
    <p style={{fontSize: '2rem', textAlign:'center'}}>Learn the basics</p>
    </h1>
    </FadeIn>
    <FadeIn>
    <div className='heroI heroguides'></div>
    </FadeIn>
    <Article/>
  </Lay>
  )
}



export default BlogHome