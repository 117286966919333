import React from 'react'
import {Link, withRouter} from 'react-router-dom';


const NavbarItemsHiddenx = () => {




  return(
    <div className='NIH-container'>
      <div className='nav-items-hiddenx'>
        <Link to='/tools' style={{textDecoration:'none'}}>
          <div>Tools</div>
        </Link>
        <Link to='/guides' style={{textDecoration:'none'}}>
          <div>Guides</div>
        </Link>
        <Link to='/tea' style={{textDecoration:'none'}}>
          <div>Tea</div>
        </Link>
      </div>     
    </div>
  )
};


export default NavbarItemsHiddenx;